import { GetStaticProps } from 'next';
import type { ReactElement } from 'react';
import { useQuerySubscription } from 'react-datocms';

import { ContentMatrix } from '@/components/content-matrix';
import { PageLayout } from '@/components/layouts/page-layout';
import { createDatoSubscription } from '@/lib/api/utils/create-dato-subscription';
import { PAGE_REVALIDATE_INTERVAL_WEEK, SITE_DOMAIN } from '@/lib/constants';
import { DatoQueryListenerOptions } from '@/types/cms-types';
import { cmsService } from '@/utils/api/services/cms.service';

type PageProps = { subscription: DatoQueryListenerOptions };

const Page = ({ subscription }: PageProps): ReactElement => {
  const {
    data: { page },
    status,
    error,
  } = useQuerySubscription(subscription);

  return (
    <PageLayout
      metaTags={page.seoMetaTags}
      canonical={SITE_DOMAIN}
      previewStatus={{ status, error }}
      headerVariant="light"
    >
      <ContentMatrix blocks={page.blocks} />
    </PageLayout>
  );
};

export const getStaticProps: GetStaticProps = async ({ preview = false }) => {
  const page = await cmsService.page.getOne('home', preview);

  if (!page || !page.slug) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      subscription: await createDatoSubscription({ page, slug: 'home', preview }),
    },
    revalidate: PAGE_REVALIDATE_INTERVAL_WEEK,
  };
};

export default Page;
